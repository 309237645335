/* Overarching styling */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.349);
}
.loose-container {
  margin:15px;
}
.dropdown-global {
  margin-right:10px;
  background-color: #60528d;
  color:white;
  padding: 10px;
  border-radius: 4px;
}
.header-global {
  text-transform:capitalize !important;
}
.table-segment-global {
  height:700px;
}
.scrollable-table-global {
  height:500px;
  max-height:500px;
  overflow-y:auto;
}

.semanticModal {
  top: unset!important;
    right: unset!important;
    bottom: unset!important;
    left: unset!important;

}
.leaseralogo {
  height:100% !important;
  width:100% !important;
}

.logininputs {
  display:flex;
  justify-content: space-between;
}

.loose-container{
  display: block;
  position: relative;
}
.loose-container::after {
  content: "";
  background-image: url('/images/Leasera.png');
  opacity: 0.2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;   
}

.semanticModal {
  top: unset !important;
  right: unset !important;
  bottom: unset !important;
  left: unset !important;
}