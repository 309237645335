.semanticModal {
    top: unset !important;
    right: unset !important;
    bottom: unset !important;
    left: unset !important;
    overflow-y: auto !important;
    height:95vh;
    
}
.modalActions {
    position:absolute;
    bottom: 0; 
    width: 100%; 
    height: 50px;
}
.modalContent{
    bottom: 0; 
    width: 100%; 
    height: 100%;
    max-height:100%;
    overflow-y:auto
}

.paybillsmodaloverview {
    width: 85% !important;
    overflow-y: hidden !important;
    margin-left: 3vw !important;
    
  }