/* Overarching styling */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.349);
}
.loose-container {
  margin:15px;
}
.dropdown-global {
  margin-right:10px;
  background-color: #60528d;
  color:white;
  padding: 10px;
  border-radius: 4px;
}
.header-global {
  text-transform:capitalize !important;
}
.table-segment-global {
  height:700px;
}
.scrollable-table-global {
  height:500px;
  max-height:500px;
  overflow-y:auto;
}

.semanticModal {
  top: unset!important;
    right: unset!important;
    bottom: unset!important;
    left: unset!important;

}
.leaseralogo {
  height:100% !important;
  width:100% !important;
}

.logininputs {
  display:flex;
  justify-content: space-between;
}

.loose-container{
  display: block;
  position: relative;
}
.loose-container::after {
  content: "";
  background-image: url('/images/Leasera.png');
  opacity: 0.2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;   
}

.semanticModal {
  top: unset !important;
  right: unset !important;
  bottom: unset !important;
  left: unset !important;
}
* {
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Merriweather', serif;
    padding: 20px;
  }
  
  a {
    color: #f06d06;
    text-decoration: none;
     
  }
  
  .box {
    padding: 2em;
    border: 1px solid #ccc;
    display: grid;
    border-radius:30px;
    grid-template-columns: repeat(2 1fr);
    margin:10px;
    width: 350px;
    height: 300px;
    float: left;
    margin: -150px 0 0 15px;
    color: black;
    background: -webkit-gradient(
       linear, left top, left bottom, from(white), color-stop(50%, white), color-stop(50%, #333), to(#333)
    );
    background: linear-gradient(
       white, white 50%, #333 50%, #333
    );
    background-size: 100% 202%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-animation: down-bump 0.4s ease;
            animation: down-bump 0.4s ease;
  }
  .box h2 {
    font-weight: 400;
    letter-spacing: -1.5px;
    line-height: 1.2;
  }
  .box h3 {
    font: 0.8em "Lucida Grande", serif;
  }
  
  .box:hover {
    background-position: 100% 100%;
    -webkit-animation: up-bump 0.4s ease;
            animation: up-bump 0.4s ease;
  }
  .box:hover h2 {
    color: #48ad26;
  }
  .box:hover h2 span {
    color: white;
  }
  .box:hover h3 {
    color: #999;
  }
  
  @-webkit-keyframes up-bump {
    0% { padding-top: 2em; }
    50% { padding-top: 1.5em; }
    100% { padding-top: 2em; }
  }
  
  @keyframes up-bump {
    0% { padding-top: 2em; }
    50% { padding-top: 1.5em; }
    100% { padding-top: 2em; }
  }
  @-webkit-keyframes down-bump {
    0% { padding-top: 2em; }
    50% { padding-top: 2.5em; }
    100% { padding-top: 2em; }
  }
  @keyframes down-bump {
    0% { padding-top: 2em; }
    50% { padding-top: 2.5em; }
    100% { padding-top: 2em; }
  }
  img {
    max-width:100%;
    width:100%;
    border-radius: 30px;
    height:200px;
  }

  .home1 {
    display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  
  /* border: 3px solid green; */
  }
.semanticModal {
    top: unset !important;
    right: unset !important;
    bottom: unset !important;
    left: unset !important;
    overflow-y: auto !important;
    height:95vh;
    
}
.modalActions {
    position:absolute;
    bottom: 0; 
    width: 100%; 
    height: 50px;
}
.modalContent{
    bottom: 0; 
    width: 100%; 
    height: 100%;
    max-height:100%;
    overflow-y:auto
}

.paybillsmodaloverview {
    width: 85% !important;
    overflow-y: hidden !important;
    margin-left: 3vw !important;
    
  }
.mix-link {
 color:blue;
}

.mix-link:hover {
    cursor:pointer;
    color:purple;
}
