* {
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Merriweather', serif;
    padding: 20px;
  }
  
  a {
    color: #f06d06;
    text-decoration: none;
     
  }
  
  .box {
    padding: 2em;
    border: 1px solid #ccc;
    display: grid;
    border-radius:30px;
    grid-template-columns: repeat(2 1fr);
    margin:10px;
    width: 350px;
    height: 300px;
    float: left;
    margin: -150px 0 0 15px;
    color: black;
    background: linear-gradient(
       white, white 50%, #333 50%, #333
    );
    background-size: 100% 202%;
    transition: all 0.2s ease;
    animation: down-bump 0.4s ease;
  }
  .box h2 {
    font-weight: 400;
    letter-spacing: -1.5px;
    line-height: 1.2;
  }
  .box h3 {
    font: 0.8em "Lucida Grande", serif;
  }
  
  .box:hover {
    background-position: 100% 100%;
    animation: up-bump 0.4s ease;
  }
  .box:hover h2 {
    color: #48ad26;
  }
  .box:hover h2 span {
    color: white;
  }
  .box:hover h3 {
    color: #999;
  }
  
  @keyframes up-bump {
    0% { padding-top: 2em; }
    50% { padding-top: 1.5em; }
    100% { padding-top: 2em; }
  }
  @keyframes down-bump {
    0% { padding-top: 2em; }
    50% { padding-top: 2.5em; }
    100% { padding-top: 2em; }
  }
  img {
    max-width:100%;
    width:100%;
    border-radius: 30px;
    height:200px;
  }

  .home1 {
    display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  
  /* border: 3px solid green; */
  }